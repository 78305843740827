<script>
import api from '@/command/api'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '@/components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import { create, editPost } from '@/api/command'
export default {
  props: {
    roomId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ...api.command.getState(),
      dataId: '',
      specificationsDataList: [],
      storeData: []
    }
  },
  mounted() {
    if (this.roomId) {
      api.command.getList.call(this, {
        url: '/market/farmProductRoom/page',
        paramsValue: {
          roomId: this.roomId
        },
        current: 1
      })
    }
  },
  methods: {
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          type: 'lt-100',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'sort',
          title: '序号',
          type: 'lt-50'
        },
        {
          dataIndex: 'productName',
          title: '特产名称',
          type: 'lt-200',
          sorter: (a, b) => a.productName.length - b.productName.length,
          customRender: (text, records) => {
            return (
              <div>
                <div class="product-order-item">
                  <img src={e.productPic} />
                  <div>{e.productName.substring(0, 25)}</div>
                </div>
              </div>
            )
          }
        },
        {
          dataIndex: 'spikePrice',
          title: '直播价格',
          type: 'lt-100'
        },
        {
          dataIndex: '',
          title: '点击次数',
          type: 'ct-100'
        },
        {
          dataIndex: '',
          title: '点击人数',
          type: 'ct-100'
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          width: '60px',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          onExport: records => {
            return ['下架', '上架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          width: '15%',
          typeData: ({ records }) => {
            return [
              {
                name: records.status == '1' ? '产品推送' : '10S冷却',
                disabled: records.status !== '1',
                onClick: () => records.status == '1' && _this.handlePropup(records)
              },
              {
                name: records.upDown == 1 ? '上架' : '下架',
                onClick: () => {
                  api.command.editPost.call(this, {
                    url: `/market/farmProductRoom/${records.upDown == 1 ? 'up' : 'down'}?id=${records.id}`
                  })
                }
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.delPost.call(this, {
                    url: `/market/farmProductRoom/delete?id=${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          }
        }
      ]
    },
    async storeSearch(value = '') {
      let searchResult = await api.command.search.call(this, {
        url: `/api/commodity/farmNativeProduct/list?canBuy=1&name=${value}`
      })
      this.storeData = searchResult.map(el => {
        return {
          ...el,
          value: el.id
        }
      })
    },
    onProductMenuClick(data) {
      this.handlegetPriorc(data.id)
    },
    getDrawerForm(source, formData) {
      if (formData.productStandardsId) {
        formData.salePrice = (
          this.specificationsDataList.find(e => e.id == formData.productStandardsId) || { salePrice: '' }
        ).salePrice
      }

      const form = [
        {
          title: '产品（单规格直播购买）',
          form: [
            {
              name: '秒杀产品',
              type: 'dropDownInput',
              key: 'productName',
              onInputSearch: this.storeSearch,
              onMenuClick: this.onProductMenuClick,
              valueKey: 'productId',
              inputProps: {
                addonBefore: <a-icon type="search" />
              },
              rules: [
                {
                  required: true
                }
              ]
            },
            {
              name: '产品规格',
              key: 'productStandardsId',
              type: 'radioButton',
              width: '100%',
              typeData: this.specificationsDataList,
              rules: [
                {
                  required: true
                }
              ]
            }
          ]
        },
        {
          title: '价格',
          form: [
            {
              name: '产品原价',
              type: 'text',
              key: 'salePrice'
            },
            {
              name: '直播价格',
              type: 'inputNumber',
              key: 'spikePrice',
              props: {
                placeholder: '请输入秒杀价格',
                addonAfter: '元',
                min: 0
              },
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            }
          ]
        },
        {
          title: '其他',
          form: [
            {
              name: '剩余库存',
              type: 'text',
              key: 'salePrice'
            },
            {
              name: '直播库存',
              type: 'inputNumber',
              key: 'inventory',
              props: {
                placeholder: '请输入秒杀库存',
                addonAfter: '件',
                min: 0
              },
              rules: [
                {
                  required: true,
                  type: 'number'
                }
              ]
            },
            {
              name: '优先排序',
              type: 'inputNumber',
              key: 'sort',
              props: {
                placeholder: '请输入0-100的数字',
                min: 0,
                max: 100
              }
            }
          ]
        }
      ]
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.dataId = records.id
        getAction('/api/market/farmProductSpike/get?id=' + records.id).then(result => {
          if (result.code == 200) {
            this.handleStatePopup(result.data)
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        this.dataId = null
        this.handleStatePopup()
      }
    },
    handlegetPriorc(productId = null) {
      getAction('/api/commodity/farmNativeProductStandards/list?productId=' + productId).then(result => {
        if (result.code == 200) {
          this.specificationsDataList = result.data.map(e => {
            return {
              ...e,
              name: e.standardsName,
              value: e.id
            }
          })
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    handleStatePopup(
      source = {
        spikePrice: '',
        inventory: '',
        sort: ''
      }
    ) {
      this.handlegetPriorc(source.productId)
      let that = this
      apiTool.showDrawer({
        title: '商品设置',
        width: '720px',
        view: DrawerForm,
        typeData() {
          return {
            productName: that.storeData
          }
        },
        viewProps: {
          data: formData => this.getDrawerForm(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 }
          }
        },
        success: ({ data, setHidden }) => {
          if (
            !(
              data.productName &&
              data.productStandardsId &&
              data.spikePrice &&
              data.beginTime &&
              data.endTime &&
              data.inventory
            )
          ) {
            that.$message.warning('请完成必填项！')
            return false
          }
          if (moment(data.beginTime) > moment(data.endTime)) {
            this.$message.warning('结束时间应大于开始时间！')
            return false
          }
          if (data.productId) {
            data.shopName = (
              this.storeData.find(e => e.id == data.productId) || {
                mainBodyName: ''
              }
            ).mainBodyName
          }
          if (data.productStandardsId) {
            data.productStandardsName = (
              this.specificationsDataList.find(e => e.id == data.productStandardsId) || {
                name: ''
              }
            ).name
          }
          api.command[!this.dataId ? 'create' : 'editPost']
            .call(this, {
              url: `/market/farmProductRoom/${!this.dataId ? 'add' : 'update'}`,
              params: {
                ...data,
                id: this.dataId,
                roomId: this.roomId
              }
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close
            },
            {
              name: '保存',
              type: 'primary',
              onClick: submit
            }
          ]
        }
      })
    }
  },
  render() {
    return <TemplateHeaderTable tableColumns={this.getColumns()} button={this.getButton()} records={this.records} />
  }
}
</script>
<style lang="less" scoped>
// /deep/.template-pagination {
//   display: none;
// }
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
</style>
